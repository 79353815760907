@import "compiled/svg";
@import "global/fonts";

$color_1: #3c3c3c;
$color_2: inherit;
$color_3: #fff;
$color_4: #6e6e6e;
$font-family_1: sans-serif;
$font-family_2: arial,sans-serif;
$font-family_3: arial black,sans-serif;
$background-color_1: #fff;
$background-color_2: #194996;
$background-color_3: transparent;

.clearfix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

.top-banner {
    &::after {
        content: "";
        display: table;
        clear: both;
    }

    position: relative;
    padding: 1.25rem 0.625rem 0.625rem;

    * {
        box-sizing: border-box;
    }
}

nav[role="navigation"] {
    &::after {
        content: "";
        display: table;
        clear: both;
    }

    position: absolute;
    top: 0;
    left: -90%;
    width: 90%;
    margin-top: 0;
    background-color: $background-color_1;
    z-index: 2;
}

.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.menu {
    float: left;
    margin: 0;
    padding: 0;
}

.icon.svg-back-to-top-icon-dims {
    width: 36px;
    height: 36px;
}

.icon {
    display: inline-block;
    vertical-align: middle;
    fill: #3c3c3c;
}

.icon.header-icons {
    fill: #3c3c3c;

    &:hover {
        fill: #3c3c3c;
    }
}

.icon.hoverable {
    &:hover {
        fill: #3c3c3c;
    }
}

html {
    font-family: $font-family_1;
    text-size-adjust: 100%;

    input[type="button"] {
        appearance: button;
        cursor: pointer;
    }
}

body {
    margin: 0;
    overflow-x: hidden;
    background: 0 0 #fff;
    font-family: $font-family_2;
    font-weight: 400;
    font-size: 16px;
    font-size: 1rem;
    letter-spacing: 0.1px;
    color: $color_1;
    -moz-osx-font-smoothing: grayscale;
}

article {
    display: block;
}

aside {
    display: block;
}

details {
    display: block;
}

figcaption {
    display: block;
}

figure {
    display: block;
}

footer {
    display: block;
    background-color: $background-color_1;
    clear: both;
}

header {
    display: block;
}

hgroup {
    display: block;
}

main {
    display: block;
}

nav {
    display: block;
}

section {
    display: block;
}

summary {
    display: block;
}

a {
    background: 0 0;
    color: $color_2;
    text-decoration: inherit;
}

b {
    font-weight: 700;
}

strong {
    font-weight: 700;
}

img {
    border: 0;
    max-width: 100vw;
}

svg {
    &:not(:root) {
        overflow: hidden;
    }

    use {
        pointer-events: none;
    }
}

button {
    font: inherit;
    margin: 0;
    overflow: visible;
    appearance: button;
    line-height: 1em;
    padding: 0.625rem 8%;
    min-height: 2.5rem;
    font-family: $font-family_3;
    font-size: 17px;
    font-size: 1.0625rem;
    transition: all 0.3s ease;
    box-sizing: border-box;
    border-radius: 4px;
    letter-spacing: 0.2px;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    cursor: pointer;
    border: 2px solid #194996;
    background-color: $background-color_2;
    color: $color_3;
    display: inline-block;
}

input {
    color: $color_2;
    font: inherit;
    margin: 0;

    &::-webkit-file-upload-button {
        border-radius: 0;
    }
}

optgroup {
    color: $color_2;
    font: inherit;
    margin: 0;
}

textarea {
    color: $color_2;
    font: inherit;
    margin: 0;
}

select {
    color: $color_2;
    font: inherit;
    margin: 0;
    text-transform: none;
}

input[type="reset"] {
    appearance: button;
    cursor: pointer;
    border-radius: 0;
}

input[type="submit"] {
    appearance: button;
    cursor: pointer;
    border-radius: 0;
}

.button {
    line-height: 1em;
    padding: 0.625rem 8%;
    min-height: 2.5rem;
    font-family: $font-family_3;
    font-size: 17px;
    font-size: 1.0625rem;
    display: inline-block;
    transition: all 0.3s ease;
    box-sizing: border-box;
    border-radius: 4px;
    letter-spacing: 0.2px;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    cursor: pointer;
    border: 2px solid #194996;
    background-color: $background-color_2;
    color: $color_3;
}

.buttonstyle {
    line-height: 1em;
    padding: 0.625rem 8%;
    min-height: 2.5rem;
    font-family: $font-family_3;
    font-size: 17px;
    font-size: 1.0625rem;
    display: inline-block;
    transition: all 0.3s ease;
    box-sizing: border-box;
    border-radius: 4px;
    letter-spacing: 0.2px;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    cursor: pointer;
    border: 2px solid #194996;
    background-color: $background-color_2;
    color: $color_3;
}

input[type="button"] {
    line-height: 1em;
    padding: 0.625rem 8%;
    min-height: 2.5rem;
    font-family: $font-family_3;
    font-size: 17px;
    font-size: 1.0625rem;
    display: inline-block;
    transition: all 0.3s ease;
    box-sizing: border-box;
    border-radius: 4px;
    letter-spacing: 0.2px;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    cursor: pointer;
    border: 2px solid #194996;
    background-color: $background-color_2;
    color: $color_3;
}

input[type="file"] {
    border-radius: 0;
}

html.menu-active {
    overflow-x: hidden;
}

ol {
    line-height: 2;
    letter-spacing: 0.2px;
    padding-left: 0.9375rem;

    li {
        padding-left: 0.9375rem;
    }
}

ul {
    line-height: 2;
    letter-spacing: 0.2px;
    padding-left: 0.9375rem;

    li {
        padding-left: 0.9375rem;
    }
}

.button-fancy-large {
    display: inline-block;
}

#wrapper {
    position: relative;
    left: 0;
    transition: left 0.2s ease-in;
    width: 100%;
    border: 0;
    margin: auto;
    background-color: $background-color_1;
}

#main {
    box-sizing: border-box;
    margin: auto;
    width: calc(100% - 30px);
    max-width: 1280px;
    padding: 1.5625rem 0;
}

.account-menu-toggle {
    display: block;
    float: left;
    min-height: 0;
    line-height: normal;
    padding: 0;
    border: 0;
    border-radius: 0;
    font-size: 1.1em;
    background-color: $background-color_3;
    color: $color_1;
    margin: 0;
}

.menu-toggle {
    display: block;
    float: left;
    min-height: 0;
    line-height: normal;
    padding: 0;
    border: 0;
    border-radius: 0;
    font-size: 1.1em;
    background-color: $background-color_3;
    color: $color_1;
    margin: 0 3% 0 0.625rem;
}

.menu-category {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: subpixel-antialiased;
}

.mobile-menu-utility-user {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: subpixel-antialiased;
    display: block;
    list-style: none;
}

.menu-utility-user {
    display: none;
    margin: 0;
    padding: 0;

    li {
        list-style: none;
        padding: 0.8em 1rem;
        border-top: 1px solid #3c3c3c;

        &:first-child {
            border: 0;
        }
    }
}

#mini-cart {
    position: absolute;
    top: 21px;
    right: 20px;
    z-index: 20;
}

.mini-cart-content {
    display: none;
}

.mini-cart-content-modal {
    display: none;
}

.skip-nav {
    position: absolute;
    left: -10000px;
}

.header-search {
    position: relative;
    z-index: 1;
    height: 40px;
    clear: both;
    width: 100%;
    border: 1px solid #b8b8b8;
    border-radius: 4px;
    margin: 1.125rem auto 0;
    background-color: $background-color_1;
    color: $color_4;
}

.primary-logo {
    position: relative;
    z-index: 1;
    height: 40px;
    width: 100px;
    background: url("../images/svg-icons/logo-mobile.svg") no-repeat;
    background-size: contain;
    margin: 0 auto;
    top: 3px;

    a {
        display: block;
        height: 100%;
    }
}

.footer-container {
    .footer-item {
        h3 {
            font-family: $font-family_3;
            font-size: 21px;
            font-size: 1.3125rem;
            font-size: 16px;
            font-size: 1rem;
            letter-spacing: 0.2px;
            position: relative;
            padding: 0 0.625rem;
            margin: 0;
            line-height: 46px;
            line-height: 2.875rem;
        }

        display: block;
        width: 100%;
        border-bottom: 1px solid #b8b8b8;
        box-sizing: border-box;
    }

    color: $color_1;
    box-sizing: border-box;
    margin: auto;
    width: calc(100% - 30px);
    max-width: 1280px;

    .footer-item.email-item {
        padding: 0.75rem 0;
    }

    .footer-item.social-item {
        border: 0;
        margin-bottom: 1.25rem;
        padding: 0 0.625rem;

        a {
            line-height: 19px;
            line-height: 1.1875rem;
        }

        .call-us {
            line-height: 24px;
            line-height: 1.5rem;
            margin-top: 1.25rem;

            a {
                font-family: $font-family_3;
                font-size: 17px;
                font-size: 1.0625rem;
            }
        }
    }

    .menu-footer {
        float: none;
    }

    a {
        color: $color_1;
        text-decoration: none;
    }
}

.h3 {
    font-family: $font-family_3;
    font-size: 21px;
    font-size: 1.3125rem;
}

h3 {
    font-family: $font-family_3;
    font-size: 21px;
    font-size: 1.3125rem;
}

.copyright {
    text-align: center;
    background: #f2f2f2;
    color: $color_1;
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 2.3333em;

    .copyright-container {
        .copy-links {
            a {
                margin: 0 0.625rem;
                color: $color_1;
                text-decoration: none;
            }
        }
    }
}

.back-to-top {
    visibility: hidden;
    position: fixed;
    bottom: 30px;
    right: 30px;
    opacity: 0;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;

    .back-to-top-icon {
        display: inline-block;
        width: 36px;
        height: 36px;
    }
}

.ui-helper-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

[class^="ui-"] {
    box-sizing: content-box;

    &::after {
        box-sizing: content-box;
    }

    &::before {
        box-sizing: content-box;
    }
}

#ModalViewDialog {
    color: $color_1;
}

.pt_storefront {
    #main {
        width: 100%;
        max-width: 100%;
        padding: 0 0 3.75rem;
    }
}

.home-bottom-second {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(25%, #f3f3f3), color-stop(58%, #fff));
    background: linear-gradient(180deg, #f3f3f3 25%, #fff 58%);
    background-position-y: 60px;
}
